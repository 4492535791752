/**
 *
 * No Item
 *
 */

import React, { memo } from 'react'
import { makeStyles } from '@mui/styles'
import Container from '@mui/material/Container'
import { Spin } from 'antd'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
    textAlign: 'center',
    color: theme.palette.primary.text
  },
  element: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
  }
}))

function Loading() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.element}>
        <Spin size='large' />
      </div>
    </div>
  )
}

Loading.propTypes = {}
export default memo(Loading)
